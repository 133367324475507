* {
  box-sizing: border-box;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececed;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-picker {
  position: absolute;
  top: 10%;
  left: 10%;
  z-index: 10;
  transition: opacity 0.3s;
}

.model-selector {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 90vh;
  max-height: 90vh;
  margin: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  div {
    h4 {
      text-align: center;
      margin: 0;
    }
    img {
      max-width: 100px;
      object-fit: contain;
    }
  }

  div:hover {
    cursor: pointer;
  }
}

.info-icon {
  position: absolute;
  bottom: 5%;
  left: 5%;

  .holder {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #a8a8a8;

    span {
      margin-left: 4px;
    }
  }
}

.info-icon:hover {
  cursor: pointer;
}

.hidden {
  opacity: 0;
}
